<template>
    <div>
        <div class="page">
            <div class="page_header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>客户案例</el-breadcrumb-item>
                    <el-breadcrumb-item>简介</el-breadcrumb-item>
                    <el-breadcrumb-item>修改</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="page_content">
                <el-form ref="form" :model="form" label-width="80px">
                    <!-- 缩略图 -->
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label_img">
                                <div class="img_size">280 X 600</div>
                                <div>缩略图</div>
                            </div>
                        </template>
                        <template>
                            <!-- <div class="form_value">
                                <el-upload
                                    :headers="{ token: token }"
                                    name="thumbnail"
                                    :action="imgBaseUrl + '/api/Project/upload'"
                                    list-type="picture-card"
                                    :multiple="false"
                                    :on-success="handleAvatarSuccess.bind(this, 'thumbnail')"
                                    :file-list="little_image_fileList"
                                    :limit="1"
                                >
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                            </div> -->
                            <uploadImg
                                :src="form.little_image"
                                @removeSrc="form.little_image = ''"
                                ref="little_image"
                            ></uploadImg>
                        </template>
                    </el-form-item>
                    <!-- 内容图片 -->
                    <el-form-item v-if="form.id === 4">
                        <template slot="label">
                            <div class="form_label_img">
                                <div class="img_size">278 X 278</div>
                                <div>内容图片</div>
                            </div>
                        </template>
                        <template>
                            <!-- <div class="form_value">
                                <el-upload
                                    :headers="{ token: token }"
                                    name="thumbnail"
                                    :action="imgBaseUrl + '/api/Project/upload'"
                                    list-type="picture-card"
                                    :multiple="false"
                                    :on-success="handleAvatarSuccess.bind(this, 'content')"
                                    :file-list="big_image_fileList"
                                    :limit="1"
                                >
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                            </div> -->
                            <uploadImg :src="form.big_image" @removeSrc="form.big_image = ''" ref="big_image"></uploadImg>
                        </template>
                    </el-form-item>
                    <!-- 标题 -->
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label">标题</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <input class="form_input" v-model="form.title" type="text" />
                            </div>
                        </template>
                    </el-form-item>
                    <!-- 简介 -->
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label">简介</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <input class="form_input" v-model="form.intro" type="text" />
                            </div>
                        </template>
                    </el-form-item>
                    <!-- 链接标题 -->
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label">链接标题</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <input class="form_input" v-model="form.project_title" type="text" />
                            </div>
                        </template>
                    </el-form-item>
                    <!-- 链接 -->
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label">链接</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <input class="form_input" v-model="form.project_url" type="text" />
                            </div>
                        </template>
                    </el-form-item>
                    <!-- 内容描述 -->
                    <el-form-item v-if="form.id === 4">
                        <template slot="label">
                            <div class="form_label">内容描述</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <input class="form_input" v-model="form.content" type="text" />
                            </div>
                        </template>
                    </el-form-item>
                    <!-- 内容备注 -->
                    <el-form-item v-if="form.id === 4">
                        <template slot="label">
                            <div class="form_label">内容备注</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <input class="form_input" v-model="form.remark" type="text" />
                            </div>
                        </template>
                    </el-form-item>
                </el-form>
            </div>

            <!-- 悬浮按钮 -->
            <div class="page_fab">
                <VyIcon name="确认" scale="4" @click.native="updataInfo"></VyIcon>
                <VyIcon name="取消" scale="4" @click.native="onModuleClose"></VyIcon>
            </div>
        </div>
    </div>
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import qs from "qs";
import uploadImg from "@/components/customs/uploadImg.vue";
import VyIcon from "@/components/customs/VyIcon.vue";

export default {
    props: ["byData"],
    components: { uploadImg, VyIcon },
    data() {
        return {
            form: {
                little_image: "",
                big_image: "",
                title: "",
                intro: "",
                project_url: "",
                project_title: "",
                content: "",
                remark: "",
            },
            // little_image_fileList: [],
            // big_image_fileList: [],
        };
    },
    mounted() {
        this.getInfo();
    },
    computed: {
        token() {
            return Cookies.get("token");
        },
    },
    methods: {
        // 上传文件 -- 显示图片
        handleAvatarSuccess(value, response) {
            if (value == "thumbnail") {
                this.form.little_image = axios.defaults.baseURL + response;
                console.log("thumbnail", response);
            } else {
                this.form.big_image = axios.defaults.baseURL + response;
                console.log("content", response);
            }
        },
        // 上传 -- 删除图片
        handleDelete(value) {
            if (value == "content") {
                this.form.big_image = "";
            } else {
                this.form.little_image = "";
            }
        },
        // 获取数据
        getInfo() {
            axios
                .get("/api/project/introshow", {
                    params: {
                        id: this.byData,
                    },
                })
                .then(res => {
                    this.form = res.data;
                    // this.little_image_fileList = res.data.little_image ? [{ name: "", url: res.data.little_image }] : [];
                    // this.big_image_fileList = res.data.big_image ? [{ name: "", url: res.data.big_image }] : [];
                });
        },
        // 完成修改
        updataInfo() {
            this.form.big_image = this.$refs.big_image.url;
            this.form.little_image = this.$refs.little_image.url;
            axios
                .post(
                    "/api/Project/introEdit",
                    qs.stringify({
                        ...this.form,
                    })
                )
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "修改成功!",
                    });
                    this.$emit("close", 0, true);
                });
        },

        // 传值 -- 关闭页面
        onModuleClose() {
            this.$emit("close", 0);
        },
    },
};
</script>
<style scoped lang="less">
.page {
    .page_content {
        width: 90%;
        padding: 40px;

        .form_label_img {
            width: 80px;
            height: 146px;
            line-height: 146px;
            text-align: center;
            color: white;
            font-size: 16px;
            border-radius: 20px 0 0 20px;
            background: linear-gradient(to right, #da8cff, #9a55ff);
            position: relative;

            .img_size {
                position: absolute;
                top: -40px;
                left: 10px;
                font-size: 14px;
            }
        }
        .form_label {
            width: 80px;
            height: 43px;
            line-height: 43px;
            text-align: center;
            color: white;
            font-size: 16px;
            border: 0;
            border-radius: 20px 0 0 20px;
            background: linear-gradient(to right, #da8cff, #9a55ff);
        }

        .form_value {
            /deep/ .el-upload {
                height: 140px;
                // display: none;
            }
            /deep/ .el-upload-list__item {
                height: 140px !important;
            }

            .form_input {
                height: 43px;
                width: 100%;
                padding-left: 15px;
                border: 1px solid #ebedf2;
                box-sizing: border-box;
                background-color: #fff;
                border-radius: 0 13px 13px 0;

                &:focus {
                    outline: 1px solid skyblue;
                }
            }
        }
    }
}
</style>
